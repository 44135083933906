import React, {Fragment} from 'react';
import {Switch, Route} from 'react-router-dom';
import checkAuth from '../auth/checkAuth';
import checkPerm from '../auth/checkPermision';

import AdminDashboard from "../containers/admin/adminDashboard";
import ErrorPage from './errorPage';
import ErrorPagePerm from './errorPagePerm';

/**
 * @description: Routing for admin
 **/
const Admin = () => {

    let renderItem = (<Route path={"/admin/*"} component={ErrorPagePerm}/>); //Show error, if not loged in

    //Check authentication
    if (checkAuth()) {
        if (checkPerm()) {
            renderItem = (
                <Fragment>
                    <Route path={"/admin"} exact component={AdminDashboard}/>
                    <Route path={"/admin/*"} component={ErrorPage}/>
                </Fragment>)
        } else {
            renderItem = (<Route path={"/admin*"} component={ErrorPagePerm}/>) //show error, if not admin
        }
    }

    //Render component
    return (
        <Switch>
            {renderItem}
        </Switch>
    );
};

export default Admin;
