import React from 'react';
import Container from '@material-ui/core/Container'
import {makeStyles} from "@material-ui/core/styles";
import Typography from '@material-ui/core/Typography'

import GenerateCode from "../../components/admin/generateCode/generateCode";
import DonatorOverview from "../../components/donator/donatorOverview/donatorOverview";
import OrderDelete from "../../components/orderDelete/orderDelete";

//Styling
const useStyle = makeStyles((theme) => ({
    container: {
        marginTop: theme.spacing(2)
    },
    typo: {
        marginTop: theme.spacing(3)
    }
}));

/**
 * @description: Component to assemble admin dashboard
 **/
const AdminDashboard = () => {
    const classes = useStyle(); //Get styling from above function

    //Render component
    return (
        <Container maxWidth={"md"} className={classes.container}>
            <Typography variant={"h5"} gutterBottom >Pin generieren</Typography>
            <GenerateCode/>
            <Typography variant={"h5"} gutterBottom className={classes.typo} >Namen ändern</Typography>
            <DonatorOverview />
            <Typography variant={"h5"} gutterBottom className={classes.typo} >Auftragsliste löschen</Typography>
            <OrderDelete/>
        </Container>
    );
};

export default AdminDashboard;
