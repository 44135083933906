import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import {withRouter} from "react-router-dom";
import checkAuth from '../../auth/checkAuth';

//Styling
const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
        cursor: "pointer"
    },
}));

/**
 * @description: Top naviation bar
 **/
const NavBar = (props) => {
    const classes = useStyles(); //Get styling from above funciton

    //Function to redirct user to /order or / depending on authentication
    const redirectToDashboard = () => {
        if(checkAuth()) {
            props.history.push('/order'); //Redirect to /order
        } else {
            props.history.push('/'); //Redirect to /
        }
    };

    //Component for Icon to toggle side navigation
    const iconSide = (
        <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu"
                    onClick={props.toggleState}>
            <MenuIcon/>
        </IconButton>
    );

    //Logout function
    const logout = () => {
        sessionStorage.removeItem('token'); //delete token in webstorage
        props.history.push('/'); //Redirect user to /
    };

    //Render component
    return (
        <div className={classes.root}>
            <AppBar position="static">
                <Toolbar>
                    {checkAuth() ? iconSide : ""}
                    <Typography variant="h6" onClick={redirectToDashboard} className={classes.title}>
                        iMixer
                    </Typography>
                    {checkAuth()?<Button onClick={logout} color="inherit">Logout</Button>:""}
                </Toolbar>
            </AppBar>
        </div>
    );
}

export default withRouter(NavBar);
