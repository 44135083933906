import React, {useState, useEffect} from 'react';
import DontaorItem from "../donatorItem/dontaorItem";
import axios from '../../../axios';
import {makeStyles} from "@material-ui/core/styles";

//Styling
const useStyle = makeStyles((theme) => ({
    cont: {
        marginBottom: theme.spacing(3)
    }
}));

/**
 * @description: Parent donator component as overview with all donators
 **/
const DonatorOverview = () => {
    const classes = useStyle(); //Get styling from function above

    const [donatorsName, setDonatorsName] = useState([]); //List of donator names

    //Get names of donators from backend
    useEffect(() => {
        axios.get('/drinks/getAllDonators', {headers: {Authorization: 'Bearer ' + sessionStorage.getItem('token')}})
            .then(response => {
                setDonatorsName(response.data); //Write donator names into state
            }).catch(err => console.log(err)) //Throw error to console
    }, []);

    //Map Array to List of donator child components
    const donatorList = donatorsName.map((ig, index) => {
        return <DontaorItem num={ig.position} old={ig.name} key={index}/>
    });

    //Render component
    return (
        <div className={classes.cont}>
            {donatorList}
        </div>
    );
};

export default DonatorOverview;
