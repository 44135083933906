import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import LocalDrinkIcon from '@material-ui/icons/LocalDrink';
import {Link} from "react-router-dom";

/**
 * @description: Child component for side navigation (every link get´s one)
 **/
const NavItem = (props) => {
    //Render component
    return (
        <ListItem button component={Link} to={props.target}>
            <ListItemIcon><LocalDrinkIcon/></ListItemIcon>
            <ListItemText primary={props.text}/>
        </ListItem>
    );
};

export default NavItem;
