import React from 'react';
import Container from '@material-ui/core/Container'
import {makeStyles} from '@material-ui/core/styles';
import OvervewDrinks from "../../components/overviewDrinks/overvewDrinks";

//Styling
const useStyle = makeStyles((theme) => ({
    container: {
        marginTop: theme.spacing(3)
    }
}));

/**
 * @description: Component to show all drinks
 **/
const OrderOverview = (props) => {
    const classes = useStyle();//Get style from above function

    //Render component
    return (
        <Container maxWidth={"md"} className={classes.container}>
            <OvervewDrinks/>
        </Container>
    );
};

export default OrderOverview;
