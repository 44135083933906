import React from 'react';
import {BrowserRouter, Route, Switch} from "react-router-dom";

//import './App.css';

import Navigation from './containers/navigation/navigation'
import Landing from "./layouts/landing";
import Orders from "./layouts/order";
import Admin from "./layouts/admin";
import ErrorPage from "./layouts/errorPage";


function App() {
  return (
    <BrowserRouter>
    <Navigation/>
      <Switch>
        <Route path={"/"} exact component={Landing} />
        <Route path={"/order"} component={Orders} />
        <Route path={"/admin"} component={Admin} />
        <Route path={"*"} component={ErrorPage} />
      </Switch>

    </BrowserRouter>
  );
}

export default App;
