import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Button from '@material-ui/core/Button';
import Slideritem from '../../components/slideritem/slideritem';
import Typography from '@material-ui/core/Typography';
import { TextField, Container } from '@material-ui/core';
import axios from '../../axios';
import ShowModal from "../../components/showModal/showModal";
import Alert from "@material-ui/lab/Alert";

//Styling
const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        marginTop: theme.spacing(2)
    },
    button: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    actionsContainer: {
        marginBottom: theme.spacing(2),
    },
    resetContainer: {
        padding: theme.spacing(3),
    },
    alert: {
        marginTop: theme.spacing(3)
    }
}));

//Function to display steps name
function getSteps() {
    return ['Alkohol', 'Mischgetränk', 'Name'];
}

/**
 * @description: Component to mix your own drink
 **/
const NewOrderOwn = (props) => {
    const classes = useStyles(); //Get styles from above function
    const [activeStep, setActiveStep] = useState(0); //Holds the current step

    //Variables for every donator
    const [donatorOne, setDonatorOne] = useState({ name: "Loading...", qty: 0 });
    const [donatorTwo, setDonatorTwo] = useState({ name: "Loading...", qty: 0 });
    const [donatorThree, setDonatorThree] = useState({ name: "Loading...", qty: 0 });
    const [donatorFour, setDonatorFour] = useState({ name: "Loading...", qty: 0 });
    const [donatorFive, setDonatorFive] = useState({ name: "Loading...", qty: 0 });
    const [donatorSix, setDonatorSix] = useState({ name: "Loading...", qty: 0 });
    const [donatorSeven, setDonatorSeven] = useState({ name: "Loading...", qty: 0 });
    const [donatorEight, setDonatorEight] = useState({ name: "Loading...", qty: 0 });

    const [maxQty, setMaxQty] = useState(400); //Maximum quantity per cup
    const [value, setValue] = useState(0); //Quantity in cup
    const [openErrServer, setOpenErrServer] = useState(false); //Error for server connection
    const [openErrName, setOpenErrName] = useState(false); //Error for name too short
    const [openModal, setOpenModal] = useState(false); //State to toggle modal
    const [name, setName] = useState(""); //Name of user

    const steps = getSteps(); //Assign steps to variable

    //Map each donator to their position
    const assignDonators = (dataSet) => {
        dataSet.forEach(ig => {
            switch (ig.position) {
                case 1:
                    setDonatorOne({name: ig.name, qty:0});
                    break;
                case 2:
                    setDonatorTwo({name: ig.name, qty:0});
                    break;
                case 3:
                    setDonatorThree({name: ig.name, qty:0});
                    break;
                case 4:
                    setDonatorFour({name: ig.name, qty:0});
                    break;
                case 5:
                    setDonatorFive({name: ig.name, qty:0});
                    break;
                case 6:
                    setDonatorSix({name: ig.name, qty: 0});
                    break;
                case 7:
                    setDonatorSeven({name: ig.name, qty: 0});
                    break;
                case 8:
                    setDonatorEight({name: ig.name, qty: 0});
                    break;
                default:
                    //Do nothing
                    break;
            }
        });
    };

    //Get all ingredients (donators) from backend
    useEffect(() => {
        axios.get("/drinks/getAllDonators",{headers: {Authorization: 'Bearer ' + sessionStorage.getItem('token')}})
            .then(response => {
                assignDonators(response.data); //Assing ingredients to position
            })
    }, []);

    //Next step
    const handleNext = () => {
        setActiveStep(activeStep + 1);
    };

    //previous step
    const handleBack = () => {
        setActiveStep(activeStep - 1);
    };

    //Save set quantity to state
    const handleChangeDonator = (don, name, qty) => {
        switch (don) {
            case 1:
                setDonatorOne({ name: name, qty: qty });
                break;
            case 2:
                setDonatorTwo({ name: name, qty: qty });
                break;
            case 3:
                setDonatorThree({ name: name, qty: qty });
                break;
            case 4:
                setDonatorFour({ name: name, qty: qty });
                break;
            case 5:
                setDonatorFive({ name: name, qty: qty });
                break;
            case 6:
                setDonatorSix({name: name, qty: qty});
                break;
            case 7:
                setDonatorSeven({name: name, qty: qty});
                break;
            case 8:
                setDonatorEight({name: name, qty: qty});
                break;
            default:
                //do nothing
                break;
        }
        setValue(donatorOne.qty + donatorTwo.qty + donatorThree.qty + donatorFour.qty + donatorFive.qty + donatorSix.qty + donatorSeven.qty +donatorEight.qty); //calculate quantity in cup
    };

    //check value if above the maximum quantity
    const checkValue = () => {
        if (maxQty - value < 0 || value === 0) {
            return true;
        } else {
            return false;
        }
    };

    //check value if above the maximum quantity for message
    const checkValueMessage = () => {
        if(maxQty - value < 0) {
            return true;
        } else {
            return false;
        }
    };

    //toggle state of modal
    const toggleStatus = () => {
        props.history.push("/order"); //redirekt to /order
        setOpenModal(!openModal);
    };

    //Assemble recipe
    const submitOrder = () => {
        if(name.length > 2) {
            setOpenErrName(false);
            let ingredients = [];
            if (donatorOne.qty > 0) {
                ingredients.push({donator: donatorOne.name, quantity: donatorOne.qty})
            }
            if (donatorTwo.qty > 0) {
                ingredients.push({donator: donatorTwo.name, quantity: donatorTwo.qty})
            }
            if (donatorThree.qty > 0) {
                ingredients.push({donator: donatorThree.name, quantity: donatorThree.qty})
            }
            if (donatorFour.qty > 0) {
                ingredients.push({donator: donatorFour.name, quantity: donatorFour.qty})
            }
            if (donatorFive.qty > 0) {
                ingredients.push({donator: donatorFive.name, quantity: donatorFive.qty})
            }
            if (donatorSix.qty > 0) {
                ingredients.push({donator: donatorSix.name, quantity: donatorSix.qty})
            }
            if (donatorSeven.qty > 0) {
                ingredients.push({donator: donatorSeven.name, quantity: donatorSeven.qty})
            }
            if (donatorEight.qty > 0) {
                ingredients.push({donator: donatorEight.name, quantity: donatorEight.qty})
            }

            //Add meta data
            const order = {
                drink_name: "own formula",
                customer_name: name,
                ingredients: ingredients
            };

            //Send recipe to backend
            axios.post("/orders/addorder", order,{headers: {Authorization: 'Bearer ' + sessionStorage.getItem('token')}})
                .then(response => {
                    setOpenModal(true); //Open modal
                })
                .catch(err => {
                    console.log(err); //thro error
                    setOpenErrServer(true); //Open server error modal
                });
        } else {
            setOpenErrName(true); //open name error modal
        }
    };

    const getStepContent = (step) => {
        switch (step) {
            case 0:
                return (
                    <StepContent>
                        <Slideritem name={donatorOne.name} qty={donatorOne.qty} handle={handleChangeDonator} nr={1} max={100} />
                        <Slideritem name={donatorTwo.name} qty={donatorTwo.qty} handle={handleChangeDonator} nr={2} max={100}  />
                        <Slideritem name={donatorThree.name} qty={donatorThree.qty} handle={handleChangeDonator} nr={3} max={100} />
                        <Slideritem name={donatorFour.name} qty={donatorFour.qty} handle={handleChangeDonator} nr={4} max={100} />
                        <Button variant="contained" color="primary" size="small" onClick={handleNext} >Weiter</Button>
                    </StepContent>
                );

            case 1:
                return (
                    <StepContent>
                        <Slideritem name={donatorFive.name} qty={donatorFive.qty} handle={handleChangeDonator} nr={5} max={380} />
                        <Slideritem name={donatorSix.name} qty={donatorSix.qty} handle={handleChangeDonator} nr={6} max={380}/>
                        <Slideritem name={donatorSeven.name} qty={donatorSeven.qty} handle={handleChangeDonator} nr={7} max={380}/>
                        <Slideritem name={donatorEight.name} qty={donatorEight.qty} handle={handleChangeDonator} nr={8} max={380}/>
                        <Button className={classes.button} variant="contained" size="small" onClick={handleBack} >Zurück</Button>
                        <Button className={classes.button} variant="contained" color="primary" size="small" onClick={handleNext}disabled={checkValue()} >Weiter</Button>
                    </StepContent>
                );
            case 2:
                return (
                    <StepContent>
                        <TextField id="standard-basic" label="Name" variant="outlined" fullWidth value={name} onChange={(event) => {setName(event.target.value)}} />
                        <Button className={classes.button} variant="contained" size="small" onClick={handleBack} >Zurück</Button>
                        <Button className={classes.button} variant="contained" color="primary" disabled={checkValue()} onClick={submitOrder}  size="small">Bestellen</Button>
                        {openErrName ? <Alert className={classes.alert} variant="filled" severity="error">Name zu
                            kurz!</Alert> : ""}
                    </StepContent>
                );
            default:
                return 'Unknown step';
        }
    };

    //Render component
    return (
        <div className={classes.root}>
            <ShowModal status={openModal} toggleStatus={toggleStatus} title={"Auftrag gesendet!"} text={"Der Auftrag wird nun zubereitet!"} btn={"OK!"}/>
            <Container maxWidth="md">
                {openErrServer ? <Alert variant="filled" severity="error">Server nicht verfügbar!</Alert> : ""}
                <Typography variant="h5" component="h5" gutterBottom>Mix dir dein Drink!</Typography>
                <Typography variant="body1" gutterBottom>Menge {value} ml</Typography>
                <Stepper activeStep={activeStep} orientation="vertical">
                    {steps.map((label, index) => (
                        <Step key={label}>
                            <StepLabel>{label}</StepLabel>
                            {getStepContent(index)}
                        </Step>
                    ))}
                </Stepper>
                {checkValueMessage()?<Alert className={classes.alert} variant="filled" severity="error">Überschreitung: max. {maxQty} ml pro Becher!</Alert>:""}
            </Container>
        </div>
    );
};

export default NewOrderOwn;
