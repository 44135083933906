import jwt from 'jsonwebtoken';

/**
 * @description: Check if user is admin
 * @return {boolean}
 **/
const CheckPermision = () => {

    const user = jwt.decode(sessionStorage.getItem('token'));
    if(user.authorization === "admin" ) {
        return true;
    } else {
        return false;
    }

};

export default CheckPermision;
