import React, {Fragment} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import NavItem from './listitem';
import checkPerm from '../../../auth/checkPermision';

//Styling
const useStyles = makeStyles({
    list: {
        width: 250,
    },
    fullList: {
        width: 'auto',
    },
});

/**
 * @description: Component to build sidelist in navigation
 **/
const MenuList = (props) => {
    const classes = useStyles(); //Get styling from function above

    //Component for admins
    const admin = (
        <Fragment>
            <Divider/>
            <List>
                <NavItem text={"Admin"} target={'/admin'}/>
            </List>
        </Fragment>
    );

    //Render component
    return (
        <div
            className={classes.list}
            role="presentation"
            onClick={props.toggleState}
            onKeyDown={props.toggleState}
        >
            <List>
                <NavItem text={"Dashboard"} target={'/order'}/>
                <NavItem text={"Rezepte"} target={'/order/new/recipe'}/>
                <NavItem text={"Mix your own Drink!"} target={'/order/new/own'}/>
                <NavItem text={"Bestellliste"} target={'/order/overview'}/>
            </List>
            {checkPerm() ? admin : ""}
        </div>
    );
};

export default MenuList;
