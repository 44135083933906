import React from 'react';
import { TextField, Grid } from '@material-ui/core';

/**
 * @description: User login component
 **/
function User(props) {

    //Submit on "enter" key
    const keyPressed = (event) => {
        if (event.key === "Enter") {
            props.submitFormular(); //execute function received from parrent via props
        }
    };

    //Render component
    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <TextField id="id" label="ID des Abends" variant="outlined" onKeyPress={keyPressed} fullWidth onChange={event => props.idHandler(event.target.value)} value={props.id}/>
            </Grid>
        </Grid>
    );
}

export default User;
