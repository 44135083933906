import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Button from "@material-ui/core/Button";

//Styling
const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        borderRadius: "5px",
        padding: theme.spacing(2, 4, 3),
    },
}));

/**
 * @description: Modal component
 **/
const ShowModal = (props) => {
    const classes = useStyles(); //get styling from above function

    //Render component
    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={props.status}
            onClose={props.toggleStatus}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={props.status}>
                <div className={classes.paper}>
                    <h2 id="transition-modal-title">{props.title}</h2>
                    <p id="transition-modal-description">{props.text}</p>
                    <Button color={"primary"} variant={"outlined"} onClick={props.toggleStatus}>{props.btn}</Button>
                </div>
            </Fade>
        </Modal>
    );
};

export default ShowModal;
