import React from 'react';
import Grid from "@material-ui/core/Grid";
import stopImage from '../assets/images/stop-min.jpg'
import Button from "@material-ui/core/Button";
import {Link} from "react-router-dom";
import Container from "@material-ui/core/Container";
import {makeStyles} from "@material-ui/core";

//Styling
const useStyles = makeStyles({
    gridItem: {
        display: 'grid',
        justifyItems: 'center'
    },
    gridItemPic: {
        width: '75%'

    }
});

/**
 * @description: Component for permission error
 **/
const Error = () => {
    const classes = useStyles(); //Get styling from above function

    //Render component
    return (
        <Container maxWidth={"lg"}>
            <Grid container direction="row" justify="center" alignItems="center" style={{height: "93.3vh"}}>
                <Grid item xs={12} sm={6} className={classes.gridItem}><img className={classes.gridItemPic} src={stopImage}
                                                                            alt={"Arbeiter"}/></Grid>
                <Grid item xs={12} sm={6}>
                    <h2>Ooops, hier scheint etwas schief gelaufen zu sein...</h2>
                    <p>
                        Bitte logge dich ein, bevor du unseren Service benutzt! <br/>
                        Danach steht dir unser automatischer Barkeeper gerne für deine Wünsche zur Verfügung!
                    </p>
                    <Button component={Link} to={'/'} variant="outlined" color="primary">Zum Login</Button>
                </Grid>

            </Grid>
        </Container>
    );
};

export default Error;
