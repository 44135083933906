import React from 'react';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

/**
 * @description: Child component for order list
 **/
const OverviewTableItem = (props) => {

    //Replace "own formula" by "Eigene Rezeptur"
    const getCocktail = () => {
      if(props.cocktail === "own formula") {
          return "Eigene Rezeptur";
      } else {
          return props.cocktail;
      }
    };

    //Change status icon
    const getStatus = () => {
        if(props.status === true) {
            return <CheckCircleOutlineIcon style={{color: "green"}} />;
        } else {
            return <ErrorOutlineIcon style={{color: "orange"}} />
        }
    };

    //Render component
    return (
        <TableRow>
            <TableCell>{props.index+1}</TableCell>
            <TableCell>{getCocktail()}</TableCell>
            <TableCell>{props.name}</TableCell>
            <TableCell>{getStatus()}</TableCell>
        </TableRow>
    );
};

export default OverviewTableItem;
