import axios from 'axios';

/**
 * @description: Configuration of axios
 **/
const IP_Backend = process.env.REACT_APP_URL;
const Port = process.env.REACT_APP_PORT;
const lnk = 'http://' + IP_Backend + ":" + Port + '/api/v1'; //Assemble backend url

//Create axios instance
const instance = axios.create({
    baseURL: lnk
});

export default instance;
