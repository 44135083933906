import React from 'react';
import {Grid, Container} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import DashboardCard from '../../components/dashboardCard/dashboardCard';
import drinkImage from '../../assets/images/rezept.jpg';
import ownDrinkImage from '../../assets/images/eigen.jpg';
import overviewImage from '../../assets/images/overview.jpeg';

//Styling
const useStyle = makeStyles((theme) => ({
    gridContainer: {
        marginTop: theme.spacing(3)
    }
}));

/**
 * @description: Dashboard component
 **/
const OrderDashboard = (props) => {
    const classes = useStyle(); //Get style from above function

    //Render component
    return (
        <Container maxWidth="md">
            <Grid container spacing={2} className={classes.gridContainer}>
                <DashboardCard
                    image={drinkImage}
                    title="Rezepte"
                    text="Hier findest du eine breite Auswahl an Drinks die wir für dich vorbereitet haben"
                    target="/order/new/recipe"
                />
                <DashboardCard
                    image={ownDrinkImage}
                    title="Mix your own Drink!"
                    text="Dir gefallen die Rezepte nicht oder du bist gerne abseits der Standards unterwegs? Worauf wartest du - mix dir Deinen Drink!"
                    target="/order/new/own"
                />
                <DashboardCard
                    image={overviewImage}
                    title="Bestellliste"
                    text="Hier siehst du alle ausgegebene und noch offene Drinks"
                    target="/order/overview"
                />
            </Grid>
        </Container>
    );
}

export default OrderDashboard;
