import React from 'react';
import Button from "@material-ui/core/Button";
import {makeStyles} from "@material-ui/core/styles";
import axios from '../../axios';

//Styling
const useStyle = makeStyles((theme) => ({
    div: {
        marginBottom: theme.spacing(3)
    }
}));

/**
 * @description: Component to delete order list
 **/
const OrderDelete = () => {
    const classes = useStyle(); //Get styling from above function

    //Advise backend to delete order list
    const deleteOrder = () => {
        axios.get('/orders/deleteallorders', {headers: {Authorization: 'Bearer ' + sessionStorage.getItem('token')}})
            .then()
            .catch(err => console.log(err)) //Throw error to console
    };

    //Render component
    return (
        <div className={classes.div}>
            <Button color="secondary" variant="contained" fullWidth onClick={deleteOrder}>Aufträge löschen</Button>
        </div>
    );
};

export default OrderDelete;
