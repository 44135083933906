import React, { Fragment } from 'react';
import { Grid, Typography, List, ListItem, ListItemText, Button, makeStyles } from '@material-ui/core';

//Styling
const useStyle = makeStyles((theme) => ({
    gridContainer: {
        marginBottom: theme.spacing(3),
        marginTop: theme.spacing(1)

    }
}));
/**
 * @description: Componente for every recipe
 **/
const Recipe = (props) => {
    const imageURL = "http://" + process.env.REACT_APP_URL + ":" + process.env.REACT_APP_PORT + '/images/'; //Build image url
    const classes = useStyle(); //Get style from above function

    //Map ingredients to list
    const IngredientsList = props.ing.map(ig => {
        return (<ListItem key={ig.donator}>            <ListItemText  primary={ig.donator +" ("+ig.quantity+" ml)"} />        </ListItem>)
    });

    //Render component
    return (
        <Fragment>
            <Grid container className={classes.gridContainer} spacing={2}>
                <Grid item xs={12} md={4} >
                    <img src={imageURL + props.picSrc} style={{ maxWidth: "100%" }} alt={"Cocktailbild"} />
                </Grid>
                <Grid item xs={false} md={1}>

                </Grid>
                <Grid item xs={12} md={7}>
                    <Typography variant="h5" >{props.name}</Typography>
                    <Typography variant="subtitle1" gutterBottom>Inhalt:</Typography>
                    <List>
                        {IngredientsList}
                    </List>
                    <Button color="primary" variant="contained" fullWidth onClick={() => props.selectDrink(props.id)} >Wählen</Button>
                </Grid>
            </Grid>

        </Fragment>
    );
}


export default Recipe;
