import React from 'react';
import Grid from "@material-ui/core/Grid";
import stopImage from '../assets/images/stop-min.jpg'
import Button from "@material-ui/core/Button";
import {Link} from "react-router-dom";
import Container from "@material-ui/core/Container";
import {makeStyles} from "@material-ui/core";

//Styling
const useStyles = makeStyles({
    gridItem: {
        display: 'grid',
        justifyItems: 'center'
    },
    gridItemPic: {
        width: '75%'

    }
});

/**
 *@description: Component for error page
 */
const Error = () => {

    const classes = useStyles(); //Get styling from above function

    //Render component
    return (
        <Container maxWidth={"lg"}>
            <Grid container direction="row" justify="center" alignItems="center" style={{height: "93.3vh"}}>
                <Grid item xs={12} sm={6} className={classes.gridItem}><img className={classes.gridItemPic} src={stopImage}
                                                                            alt={"Arbeiter"}/></Grid>
                <Grid item xs={12} sm={6}>
                    <h2>Ooops, hier scheint etwas schief gelaufen zu sein...</h2>
                    <p>
                        Wir schicken bald möglichst einen Barkeeper, um dir weiterhin Drinks zu zu bereiten! <br/>
                        Alternativ kannst du unter dem angegeben Link einen Drink in Auftrag geben!
                    </p>
                    <Button component={Link} to={'/order'} variant="outlined" color="primary">Zur Bestellung</Button>
                </Grid>

            </Grid>
        </Container>
    );
};

export default Error;
