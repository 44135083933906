import React, { useState } from 'react';
import Typography from '@material-ui/core/Typography';
import Slider from '@material-ui/core/Slider';

/**
 * @description: Component for slider; show one per donator
 **/
const Slideritem = (props) => {
    const [value, setValue] = useState(props.qty); //State for quantity; initial value comes from props

    //Function to handle slider move
    const handleEvent = (event, val) => {
        props.handle(props.nr, props.name, val);
        setValue(val);
    };

    //Funciton to hide empty donator
    const checkHide = () => {
        if (props.name === "none") {
            return {display: "none"}
        }
    };

    //Render component
    return (
        <div style={checkHide()}>
            <Typography id="discrete-slider" gutterBottom>
                {props.name}
            </Typography>
            <Slider
                defaultValue={props.qty}
                value={value}
                //aria-valuetext={value}
                aria-labelledby="discrete-slider"
                valueLabelDisplay="auto"
                step={20}
                marks
                min={0}
                max={props.max}
                hidden={checkHide()}
                onChange={handleEvent}
            />
        </div>
    );
};

export default Slideritem;
