import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core';
import motionPic from '../assets/images/DSC00108-min.JPG';
import {withRouter} from 'react-router-dom';
import axios from '../axios';
import Alert from '@material-ui/lab/Alert';

import Admin from '../components/login/admin';
import User from '../components/login/user';

//Styling
const useStyles = makeStyles(theme => ({
    root: {
        height: '93.4vh',
        backgroundImage: `url(${motionPic})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat'
    },
    gridContainer: {
        backgroundColor: 'white',
        padding: '20px 10px',
        borderRadius: '10px'
    },
    container: {
        height: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex'
    }
}));

/**
 * @description: Component for landing page
 **/
const Landing = (props) => {
    const classes = useStyles(); //Get style from above function

    const [admin, setAdmin] = useState(false); //Boolean for admin to toggle function of login
    const [user, setUser] = useState(""); //Name of user
    const [password, setPassword] = useState(""); //Password
    const [id, setId] = useState(""); //ID of user (pin)
    const [openErr, setOpenErr] = useState(false); //State to toggle error

    //Handler to write password from textfield into state
    const eventHandlerPassword = (pw) => {
        setPassword(pw);
    };

    //Handler to write user from textfield into state
    const eventHandlerUser = (usr) => {
        setUser(usr);
    };

    //Handler to write id (pin) from textfield into state
    const eventHandlerId = (uid) => {
        setId(uid);
    };

    //Render from depending of admin == true
    let renderForm = () => {
        if (admin) {
            return <Admin userHandler={eventHandlerUser} passwordHandler={eventHandlerPassword} submitFormular={submitForm} user={user} password={password} />
        } else {
            return <User idHandler={eventHandlerId} submitFormular={submitForm} id={id} />
        }
    };

    //Function to change state of admin
    const toggleAdmin = () => {
        setAdmin(true);
    };

    //Function to change state of user
    const toggleUser = () => {
        setAdmin(false);
    };

    //Function to submit formular
    const submitForm = () => {
        if(user !== "" && password !== "" && admin === true) {
            axios.post("/users/login", {
                user: user,
                password: password
            }).then(response => {
                sessionStorage.setItem('token', response.data.token); //Write token into session storage
                props.history.push("/order") //redirect user to /order
            }).catch(err => {
                console.log(err); //throw error to console
                setOpenErr(true); //Open error information
            })
        } else if (id !== "" && admin === false) {
            axios.get("/users/verifyPIN/"+id)
                .then(response => {
                    sessionStorage.setItem('token', response.data.token); //write token to sessionstorage
                    props.history.push("/order") //Redirect user to /order
                })
                .catch(err => {
                    console.log(err); //Throw error to console
                    setOpenErr(true); //open error information
                })
        }
    };

    //Render component
    return (
        <div className={classes.root}>
        <Container maxWidth={"xs"} className={classes.container}>
            <Grid container spacing={2} className={classes.gridContainer}>
                <Grid item xs={6}>
                    <Button variant="contained"  onClick={toggleAdmin} fullWidth>Admin</Button>
                </Grid>
                <Grid item xs={6}>
                    <Button variant="contained" color="primary" onClick={toggleUser} fullWidth>ID</Button>
                </Grid>
                <Grid item xs={12}>
                    {renderForm()}
                </Grid>
                <Grid item xs={12}>
                    <Button variant="contained" color="primary" fullWidth onClick={submitForm}>Los geht`s</Button>
                </Grid>
                {openErr ?
                    <Grid item xs={12}>
                        <Alert variant="filled" severity="error">Angegebene Daten inkorrekt!</Alert>
                    </Grid>: ""}
            </Grid>
        </Container>
        </div>
    );
};

export default withRouter(Landing);
