import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import {withRouter} from 'react-router-dom';
import {Grid} from '@material-ui/core';

//Sytling
const useStyle = makeStyles((theme) => ({
    image: {
        height: "250px"
    }
}));

/**
 * @description: Card component
 **/
const DashboardCard = (props) => {
    const classes = useStyle();

    //Redirect to target (props.target)
    const redirect = () => {
        props.history.push(props.target);
    };

    //Render component
    return (
        <Grid item xs={12} md={6}>
            <Card>
                <CardActionArea onClick={redirect}>
                    <CardMedia
                        component="img"
                        alt={props.title}
                        image={props.image}
                        title={props.title}
                        className={classes.image}
                    />
                    <CardContent>
                        <Typography variant="h5" component="h5" gutterBottom>{props.title}</Typography>
                        <Typography variant="body2" color="textSecondary" component="p">{props.text}</Typography>
                    </CardContent>
                </CardActionArea>
                <CardActions>
                    <Button color="primary" variant="contained" fullWidth onClick={redirect}>LOS</Button>
                </CardActions>
            </Card>
        </Grid>
    );
}

export default withRouter(DashboardCard);
