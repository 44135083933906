import React, {Fragment} from 'react';
import {Switch, Route} from 'react-router-dom'
import checkAuth from '../auth/checkAuth';

import NewOrderRecipe from '../containers/order/newOrderRecipe'
import OrderDashboard from '../containers/order/orderDashboard'
import NewOrderOwn from '../containers/order/newOrderOwn'
import OrderOverview from "../containers/order/orderOverview";
import ErrorPagePerm from './errorPagePerm';

/**
 * @description: Routing of order
 **/
const Orders = (props) => {
    let renderItem = (<ErrorPagePerm/>); //Renders if not loged in

    //Check login and redirect
    if (checkAuth()) {
        renderItem = (
            <Fragment>
                <Route path={"/order/"}  exact component={OrderDashboard}/>
                <Route path={"/order/new/recipe/"} exact component={NewOrderRecipe}/>
                <Route path={"/order/new/own/"} exact  component={NewOrderOwn}/>
                <Route path={"/order/overview/"} exact component={OrderOverview}/>
            </Fragment>
        )
    }

    //Render component
    return (
        <Switch>
            {renderItem}
        </Switch>
    );
};

export default Orders;
