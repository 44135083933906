import React, {useState, useEffect} from 'react';
import Grid from "@material-ui/core/Grid";
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Typography from "@material-ui/core/Typography";
import {makeStyles} from "@material-ui/core/styles";
import axios from '../../../axios';

//Styling
const useStyle = makeStyles((theme) => ({
    typo: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(1)
    }
}));

/**
 * @description: Child donator element
 **/
const DontaorItem = (props) => {
    const classes = useStyle(); //Get styling from function above

    const [donName, setDonName] = useState(""); //New name of donator
    const [oldName, setOldName] = useState("Loading..."); //Old name of donator

    //Send new name of donator to backend
    const updateDonator = () => {
      axios.post('/drinks/changedonator', {pos: props.num, name: donName}, {headers: {Authorization: 'Bearer ' + sessionStorage.getItem('token')}})
          .then(response => {
                setOldName(response.data.name); //set new name as old name
          }).catch(err => console.log(err))
    };

    //get old names from parent component via props
    useEffect(() => {
        setOldName(props.old); //set old name received from parent
    }, []);

    //Render component
    return (
        <div>
            <Typography variant={"h6"} className={classes.typo}>{props.num}. Position</Typography>
            <Grid container direction="row" justify="center" alignItems="center" spacing={2} >
                <Grid item xs={12} md={4}>
                    <TextField
                        label="Alt"
                        value={oldName}
                        InputProps={{
                            readOnly: true,
                        }}
                        variant="outlined"
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <TextField
                        id="filled-read-only-input"
                        label="Neu"
                        value={donName}
                        variant="outlined"
                        onChange={(event => setDonName(event.target.value))}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <Button variant="contained" size="large" color="primary" fullWidth onClick={updateDonator}>Ändern</Button>
                </Grid>
            </Grid>
        </div>
    );
};

export default DontaorItem;
