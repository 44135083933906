import jwt from "jsonwebtoken";

/**
 * @description: Check if user is authenticated
 * @return {boolean}
 **/
const CheckAuth = () => {
    try {
        const decode = jwt.verify(sessionStorage.getItem('token'), process.env.REACT_APP_SECRET);
        return true;
    } catch (err) {
        return false;
    }
};

export default CheckAuth;
