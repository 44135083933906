import React from 'react';
import Grid from '@material-ui/core/Grid'
import { TextField } from '@material-ui/core';

/**
 * @description: Admin login component
 **/
const Admin = (props) => {

    //Submit on "enter" key
    const keyPressed = (event) => {
        if (event.key === "Enter") {
            props.submitFormular(); //execute function received from parrent via props
        }
    };

    //Render component
    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <TextField id="admin" label="Admin" variant="outlined" fullWidth onChange={event => props.userHandler(event.target.value)} value={props.user} />
            </Grid>
            <Grid item xs={12}>
                <TextField id="password" label="Passwort" variant="outlined" type="password" onKeyPress={keyPressed} fullWidth onChange={event => props.passwordHandler(event.target.value) } value={props.password} />
            </Grid>

        </Grid>

    );
};

export default Admin;
