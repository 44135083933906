import React, {useState, useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import OverviewTableItem from "./overviewTableItem/overviewTableItem";
import axios from '../../axios';

//Styling
const useStyle = makeStyles((theme) => ({
    table: {
        marginBottom: theme.spacing(2)
    }
}));

/**
 * @description: Parent component for order list
 **/
const OvervewDrinks = (props) => {
    const classes = useStyle(); //Get style from above function

    const [order, setOrders] = useState([]); //Array of orders

    //Get placed orders from backend
    useEffect(()=> {
        axios.get('/orders/getorderadmin', {headers: {Authorization: 'Bearer ' + sessionStorage.getItem('token')}})
            .then(response => {
                setOrders(response.data); //Write orders into state
            })
            .catch(err => console.log(err)) //Throw error to console
    }, []);

    //Map Array to child component
    const orderList = order.map((ig, index) => {
        return (<OverviewTableItem cocktail={ig.drink_name} name={ig.customer_name} status={ig.work_done} key={index} index={index}/>)
    });

    //Render component
    return (
        <TableContainer component={Paper} className={classes.table}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Nr.</TableCell>
                        <TableCell>Cocktailname</TableCell>
                        <TableCell>Nutzer</TableCell>
                        <TableCell>Status</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {orderList}
                </TableBody>
            </Table>
        </TableContainer>


    );
};

export default OvervewDrinks;
