import React, {useState} from 'react';
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import axios from "../../../axios";

/**
 * @description: Generate pin for standard user (not admin)
 **/
const GenerateCode = (props) => {
    const [code, setCode] = useState(0); //Pin
    const [validity, setValidity] = useState(0); //Validity

    //Request pin from Backend
    const requestCode = () => {
        axios.post('/users/generatepin', {validity:validity},{headers: {Authorization: 'Bearer ' + sessionStorage.getItem('token')}})
            .then(response => {
                setCode(response.data.customerID); //Save pin in state
            }).catch(err => {
            console.log(err); //throw error to console
        })
    };

    //Function to send text from textfield to state
    const handleChange = (event) => {
        setValidity(event.target.value);
    };

    //Render component
    return (
        <Grid container
              spacing={2}
              direction="row"
              justify="center"
              alignItems="center">
            <Grid item xs={12} md={5}>
                <TextField fullWidth variant="outlined" value={code}/>
            </Grid>
            <Grid item xs={12} md={3}>
                <FormControl variant="outlined" style={{width: "100%"}}>
                    <InputLabel id="demo-simple-select-outlined-label">Gültigkeit</InputLabel>
                    <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={validity}
                        onChange={handleChange}
                        label="Gültigkeit"
                        fullWidth
                    >
                        <MenuItem value={0}>
                            <em>None</em>
                        </MenuItem>
                        <MenuItem value={3600000}>1 Stunde</MenuItem>
                        <MenuItem value={7200000}>2 Stunden</MenuItem>
                        <MenuItem value={10800000}>3 Stunden</MenuItem>
                        <MenuItem value={18000000}>5 Stunden</MenuItem>
                        <MenuItem value={28800000}>8 Stunden</MenuItem>
                        <MenuItem value={36000000}>10 Stunden</MenuItem>
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={12} md={4}>
                <Button variant="contained" color="primary" size={"large"} fullWidth
                        onClick={requestCode}>Generieren</Button>
            </Grid>
        </Grid>

    );
};

export default GenerateCode;
