import React, {Fragment} from 'react';
import Drawer from '@material-ui/core/Drawer';
import MenuList from './list';

/**
 * @description: Parent component for side navigation
 **/
const SideBar = (props) => {
    //Render component
    return (
        <div>
            <Fragment>
                <Drawer anchor={"left"} open={props.state} onClose={props.toggleState} onClick={props.toggleState}>
                    <MenuList/>
                </Drawer>
            </Fragment>

        </div>
    );
};

export default SideBar;
