import React, {Fragment, useState} from 'react';
import NavBar from '../../components/navigation/bar';
import SideBar from '../../components/navigation/sidelist/side';

/**
 * @description: Component to assemble navigation
 **/
const Navigation = (props) => {
    const [state, setState] = useState(false); //State for togglestate of sidelist

    //Function to toggle side list
    const toggleState = () => {
        setState(!state);
    };

    //Render component
    return (
        <Fragment>
            <NavBar toggleState={toggleState} />
            <SideBar state={state} toggleState={toggleState} />
        </Fragment>
    );
};

export default Navigation;
