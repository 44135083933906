import React, {useState, useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import {TextField, Container} from '@material-ui/core';
import Recipe from '../../components/recipe/recipe';
import axios from '../../axios';
import Alert from '@material-ui/lab/Alert';
import ShowModal from "../../components/showModal/showModal";

//Styling
const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        marginTop: theme.spacing(2)
    },
    button: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    actionsContainer: {
        marginBottom: theme.spacing(2),
    },
    resetContainer: {
        padding: theme.spacing(3),
    },
    alert: {
        marginTop: theme.spacing(2)
    }
}));

/**
 * @description: Component to order a drink based on a known recipe
 **/
const NewOrderRecipe = (props) => {
    const classes = useStyles(); //Get styling from above function
    const [activeStep, setActiveStep] = useState(0); //Number of step
    const [selectedRecipe, setSelectedRecipe] = useState(undefined); //
    const [name, setName] = useState(""); //Name of user
    const [recipes, setRecepies] = useState(null); //List of recipes
    const [openErrServer, setOpenErrServer] = useState(false); //State for server error
    const [openErrName, setOpenErrName] = useState(false); //State for name error
    const [openModal, setOpenModal] = useState(false); //Sate to open modal

    //Placeholder while data is loading
    let RecipeList = <p>Loading...</p>;

    //Get recipes from backend
    useEffect(() => {
        axios.get('/drinks/getAllFormulas', {headers: {Authorization: 'Bearer ' + sessionStorage.getItem('token')}})
            .then(response => {
                setRecepies(response.data); //write recipes into state
            }).catch(err => {
            console.log(err); //Throw error to console
            setOpenErrServer(true); //Open server error alert
        })
    }, []);

    //Go one step forward
    const handleBack = () => {
        setActiveStep(activeStep - 1);
    };

    //Go one step backward
    const handleSelect = (id) => {
        setSelectedRecipe(id);
        setActiveStep(activeStep + 1);
    };

    //Build list of recipes
    if (recipes != null) {
        RecipeList = recipes.map((ig, index) => {
            if(ig.name !== "own formula") {
                return (
                    <Recipe key={index} name={ig.name} picSrc={ig.image_name} ing={ig.ingredients} selectDrink={handleSelect}
                            id={index}/>)
            } else {
                return null;
            }

        });
    }

    //Toggle state of confirmation modal
    const toggleStatus = () => {
        props.history.push("/order"); //Redirect user to /order
      setOpenModal(!openModal); //Close modal
    };

    //Send order to backend
    const submitOrder = () => {
        //check length of name; must be more than two diggits
        if (name.length > 2) {
            setOpenErrName(false);
            //send order
            axios.post("/orders/addOrder", {
                drink_name: recipes[selectedRecipe].name,
                customer_name: name
            }, {headers: {Authorization: 'Bearer ' + sessionStorage.getItem('token')}}).then(result => {
               setOpenModal(true); //Open confirmation modal
            }).catch(err => {
                console.log(err); //throw error to console
                setOpenErrServer(true); //display server error
            })
        } else {
            setOpenErrName(true);//display name error
        }

    };

    //Show step based on number in state step
    const getStepContent = (step) => {
        switch (step) {
            case 0:
                return (
                    <StepContent>
                        {RecipeList}
                    </StepContent>
                );

            case 1:
                return (
                    <StepContent>
                        <TextField id="standard-basic" label="Name" variant="outlined" fullWidth
                                   onChange={(event) => {
                                       setName(event.target.value)
                                   }}/>
                        <Button className={classes.button} variant="contained" size="small"
                                onClick={handleBack}>Zurück</Button>

                        <Button className={classes.button} variant="contained" color="primary" size="small"
                                onClick={submitOrder}>Weiter</Button>

                        {openErrName ? <Alert className={classes.alert} variant="filled" severity="error">Name zu
                            kurz!</Alert> : ""}

                    </StepContent>
                );
            default:
                return 'Unknown step';
        }
    };

    //Render component
    return (
        <div className={classes.root}>
            <ShowModal status={openModal} toggleStatus={toggleStatus} title={"Auftrag gesendet!"} text={"Der Auftrag wird nun zubereitet!"} btn={"OK!"} />
            <Container maxWidth="md">
                {openErrServer ? <Alert variant="filled" severity="error">Server nicht verfügbar!</Alert> : ""}
                <Typography variant="h5" component="h5" gutterBottom>Vorhandene Rezepte</Typography>
                <Stepper activeStep={activeStep} orientation="vertical">
                    <Step>
                        <StepLabel>Rezept</StepLabel>
                        {getStepContent(activeStep)}
                    </Step>
                    <Step>
                        <StepLabel>Abschluss</StepLabel>
                        {getStepContent(activeStep)}
                    </Step>
                </Stepper>
            </Container>
        </div>
    );
};

export default NewOrderRecipe;

